<template>
  <div>
    <!-- <v-btn
      v-if="$admin.can('md-makes.create')"
      color="primary"
      dark
      @click="openModal"
    >
      {{ $t("add_new") }}
    </v-btn> -->
    <!-- v-if="isVisibleDialog" -->
    <v-dialog
      v-model="isVisibleDialog"
      persistent
      max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="$admin.can('tenant-configurations.create')"
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
          @click="openModal"
        >
          {{ $t("add_new") }}
        </v-btn>
      </template>

      <v-form
        ref="form"
        lazy-validation
      >
        <v-card>
          <v-card-title>
            <span class="text-h3">{{ $t("add_new") }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  v-if="authUser.isAdmin"
                  cols="12"
                  sm="6"
                >
                  <v-select
                    v-model="item.tenant_id"
                    dense
                    :disabled="tenantLoading"
                    :loading="tenantLoading"
                    :items="tenantsList"
                    item-text="name"
                    item-value="id"
                    :label="$t('select_tenant_*')"
                    :rules="[rules.required]"
                    @change="onTenantChange"
                  />
                </v-col>
                <v-col
                  v-for="(row, i) in config"
                  :key="i"
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="configData[row.key]"
                    dense
                    :label="`${row.label} *`"
                    :rules="[rules.required]"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <small>{{ $t("indicates_required_field") }}</small>
            <v-spacer />
            <v-btn
              color="red darken-1"
              text
              @click="closeForm()"
            >
              Close
            </v-btn>
            <v-btn
              color="primary"
              text
              :loading="isLoadingSave"
              @click="saveItem(item)"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { validationRules } from "@/mixins/validationRules";

export default {
  mixins: [validationRules],
  props: {
    filterOptions: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      isVisibleDialog: false,
      isLoadingSave: false,
      item: {},
      config: [],
      configData: {},
    };
  },

  computed: {
    ...mapGetters({
      authUser: "getUser",
      tenantLoading: "tenantsManagement/getIsLoading",
      tenantsList: "tenantsManagement/getTenantsList",
      //
      tenantBased: "tenantsConfigurations/getTenantBased",
    }),
  },

  // async mounted() {},

  methods: {
    openModal() {
      this.isVisibleDialog = !this.isVisibleDialog;
      if (!this.authUser.isAdmin) {
        this.onTenantChange(this.authUser.tenant_id);
      }
    },
    closeForm() {
      this.$refs.form.reset();
      this.config = [];
      this.configData = {};
      this.$store.commit("tenantsConfigurations/SET_TENANT_BASED", {});

      this.isVisibleDialog = false;
    },
    async saveItem(item) {
      if (this.$refs.form.validate()) {
        this.isLoadingSave = true;
        let params = {
          tenant_id: item.tenant_id,
          configuration: { ...this.configData },
        };
        await this.$store
          .dispatch("tenantsConfigurations/add_update", params)
          .then(() => {
            this.$store.dispatch(
              "alerts/success",
              this.$t("created_successfully")
            );
            // Callign list Again
            this.$store.dispatch(
              "tenantsConfigurations/list",
              this.filterOptions
            );
            this.closeForm();
          })
          .catch((error) => {
            this.$store.dispatch("alerts/error", error.response?.data?.message);
          })
          .finally(() => (this.isLoadingSave = false));
      }
    },

    async onTenantChange(val) {
      await this.$store.commit("tenantsConfigurations/SET_TENANT_BASED", {});
      this.config = [];

      if (val) {
        await this.$store
          .dispatch("tenantsConfigurations/tenantBased", {
            tenant_id: val,
          })
          .then((data) => {
            let params = {};
            data.configuration_fields.forEach((el) => {
              params.key = el.name;
              params.value = data.configuration[el.name] || 0;
              params.label = el.label;
              this.configData[el.name] = params.value;
            });
            this.config = [params];
          });
      }
    },
  },
};
</script>
